import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { DEFAULT_ZIPCODE } from '../constants/default';
import { STARTTIMELA, STARTTIMESF } from '../constants/deliveryMessageTiming';
import { ASAP, SCHEDULE } from '../constants/deliveryType';
import appContext from '../Context/appContext';
import { convertEstimatedTimeToLocal } from '../helpers/MapHelper';
import { splitIntoDateTimeComponents } from '../helpers/timeHelpers';
import { useDeliveryDetails, useHexAsapDeliveryDetails } from '../hooks/app';
import RemainingTime from './ASAPRemainingTime';

export default function DeliveryTiming({ currentTab, showNextWindow, setShowNextWindow, onlyText, fullText }) {
  const { deliveryDetails: { postcode = DEFAULT_ZIPCODE, latitude, longitude } } = useSelector(state => state.user)

  const {
    data: {
      locationId,
      avgDeliveryTime,
      deliveryThreshold,
      isHexLocation,
      asapStartTime,
      asapEndTime,
      asapReOpensAt,
      asapTimer,
      asapEnabled
    },
    isLoading
  } = useDeliveryDetails(false, true);


  const { data: hexAsapDeliveryDetails } = useHexAsapDeliveryDetails(locationId, [postcode, latitude, longitude], {
    refreshWhenHidden: false,
    refreshInterval: 0,
    revalidateOnMount: true,
    revalidateOnFocus: true
  });

  const interval = useRef(null);
  const [remainingTime, setRemainingTime] = useState(-1);
  const timeLeft = asapTimer;
  const remainingHours = parseInt(remainingTime / 3600);
  const remainingSeconds = remainingTime % 60;
  const remainingMinutes = parseInt(remainingTime / 60) % 60;

  let showLAMessage = true;
  let showSFMessage = false;

  const currentTime = new Date().toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles', hourCycle: 'h23' });


  if (locationId === 1 && currentTime > STARTTIMELA) {
    showLAMessage = true;
  } else if (locationId === 2 && currentTime > STARTTIMESF) {
    showSFMessage = true;
  }

  const newAsapDeliveryDetails = {
    newAsapDeliveryWindow: hexAsapDeliveryDetails?.deliveryDetails?.asap_standard_delivery_window || '',
    newAsapPriorityDeliveryThreshold: hexAsapDeliveryDetails?.deliveryDetails?.asap_priority_delivery_threshold || '',
    newAsapPriorityDeliveryFee: hexAsapDeliveryDetails?.deliveryDetails?.asap_priority_delivery_fee || ''
  };

  const { newAsapDeliveryWindow, newAsapPriorityDeliveryThreshold } = newAsapDeliveryDetails || {};

  let asapStandardDeliveryTime = '';
  let asapPriorityDeliveryTime = '';

  if (currentTab === ASAP && currentTime >= asapStartTime && currentTime <= asapEndTime) {
    if (isHexLocation && hexAsapDeliveryDetails?.deliveryDetails) {
      asapStandardDeliveryTime = newAsapDeliveryWindow || '';
      asapPriorityDeliveryTime = deliveryThreshold ? newAsapPriorityDeliveryThreshold || '' : '';
    } else if (showLAMessage || showSFMessage) {
      asapStandardDeliveryTime = avgDeliveryTime || '';
      asapPriorityDeliveryTime = deliveryThreshold || '';
    }
  }

  const clearIntervals = () => {
    clearInterval(interval.current);
  };

  const renderMinutes = () => {
    let text = '';

    if (remainingMinutes) {
      text = `${remainingMinutes}m : `;
    } else if (remainingHours) {
      text = '0m:';
    }
    return text;
  };

  const renderSeconds = () => {
    let text = '';
    if (remainingSeconds) {
      if (remainingSeconds < 10) {
        text = `0${remainingSeconds}s`;
      } else {
        text = `${remainingSeconds}s`;
      }
    } else if (remainingMinutes) {
      text = '00s';
    }
    return text;
  };

  function renderCurrentTime(minutesToAdd, threshold = false) {
    if (minutesToAdd) {
      const adjustedMinutes = threshold && minutesToAdd < 30 ? 30 : minutesToAdd;

      // round off the to the next 5th minute
      const factor = 1000 * 60 * 5;
      const dateObj = convertEstimatedTimeToLocal(adjustedMinutes, true);
      const millis = dateObj.getTime();

      if (dateObj.getMinutes() % 5 === 0) {
        return splitIntoDateTimeComponents(dateObj, true).time12;
      }
      return splitIntoDateTimeComponents(Math.ceil(millis / factor) * factor, true).time12;
    }
    return '';
  }

  useEffect(() => {
    const { hours = 0, minutes = 0, seconds = 0 } = timeLeft || {};
    if (Object.keys(asapTimer).length) {
      setRemainingTime(hours * 3600 + minutes * 60 + seconds);
    }
  }, [timeLeft]);

  useEffect(() => {
    const calculateAndSetTime = () => {
      if (remainingTime > 0) {
        setRemainingTime(remainingTime - 1);
      } else if (remainingTime == 0) {
        setRemainingTime(-1)
      }
    };

    interval.current = setInterval(() => {
      calculateAndSetTime();
    }, 1000);

    return () => {
      clearIntervals();
    };
  }, [remainingTime]);

  function adjustTimeRange(time) {
    const timeStr = time.toString();
    const rangeMatch = timeStr.match(/(\d+)-(\d+)/);
    const hasMins = timeStr.includes('mins');
    const singleTimeMatch = timeStr.match(/(\d+)/);

    if (rangeMatch) {
      const [_, start, end] = rangeMatch;
      if (parseInt(start) < 30) {
        return hasMins ? "30-60 mins" : "30-60";
      }
    } else if (singleTimeMatch) {
      const [singleTime] = singleTimeMatch;
      if (parseInt(singleTime) < 30) {
        return hasMins ? "30-60 mins" : "30-60";
      }
    }
    return time;
  }

  function renderDeliveryTime() {
    let standardTime = '';
    let priorityTime = '';
    if (asapPriorityDeliveryTime) {
      if (isHexLocation && hexAsapDeliveryDetails?.deliveryDetails && !onlyText) {
        priorityTime = renderCurrentTime(asapPriorityDeliveryTime);
      } else {

        priorityTime = onlyText ? `${asapPriorityDeliveryTime}` : `${asapPriorityDeliveryTime} mins`;
      }
    }
    if (asapStandardDeliveryTime) {

      if (isHexLocation && hexAsapDeliveryDetails?.deliveryDetails) {
        const standardTimeSplit = asapStandardDeliveryTime.split(' - ');
        standardTime = onlyText ? `${standardTimeSplit.toString().replace(",", "-")}` : `${renderCurrentTime(Number(standardTimeSplit[0]))} - ${renderCurrentTime(Number(standardTimeSplit[1]))}`;
      } else if (asapStandardDeliveryTime) {
        standardTime = onlyText ? `${asapStandardDeliveryTime}` : `${asapStandardDeliveryTime} mins`;
      }
    }

    if (standardTime) {

      if (onlyText) {
        const hasRange = (time) => /\d+\s*-\s*\d+/.test(time);
        const adjustTime = (time) => {
          const adjustedTime = adjustTimeRange(time);
          return hasRange(adjustedTime) ? `${adjustedTime} mins` : `${adjustedTime} mins`;
        };
        if (fullText && asapTimer && Object.keys(asapTimer).length && remainingHours < 1) {
          return <>Super Fast menu is closing in <b className='text-[#D32F2F]'>{renderMinutes()}{renderSeconds()}</b></>
        }
        if (standardTime && !priorityTime) {
          return fullText ? `Super Fast mode delivers in ${adjustTime(standardTime)}`
            : `Get in ${adjustTime(standardTime)}`;
        } else if (standardTime && priorityTime) {
          // If priorityTime has a range, use only priorityTime
          if (hasRange(priorityTime)) {
            return fullText ? `Super Fast mode delivers in ${adjustTime(priorityTime)}`
              : `Get in ${adjustTime(priorityTime)}`;
          }
          // If standardTime has a range, use only standardTime
          if (hasRange(standardTime)) {
            return fullText ? `Super Fast mode delivers in ${adjustTime(standardTime)}`
              : `Get in ${adjustTime(standardTime)}`;
          }
          // If neither has a range, show both
          return fullText ? `Super Fast mode delivers in ${adjustTime(`${priorityTime} - ${standardTime}`)}`
            : `Get in ${adjustTime(standardTime)}`;
        } else {
          return fullText ? 'Super Fast mode delivers in 60-90 mins' :
            'Get in 60-90 mins';
        }
      }
      return (
        <div className='delivery-message-wrapper'>
          <div className="delivery-timing-new">
            <div className='asap-on'>
              <p className={`m-0 standard ${standardTime ? "" : "d-none"}`}><i className="icon-Asap-Priority"></i>Standard: <span className='standard-time'> {standardTime}</span></p>
              <p className={`m-0 priority ${priorityTime ? "" : "d-none"}`}><i className="icon-Asap-Standard"></i>Priority: <span className='priority-time'> {priorityTime}</span></p>
            </div>
            {(asapTimer && Object.keys(asapTimer).length && remainingHours < 1) ? <div className="asap-remaining">
              <p className='text-center'>Super Fast Closes in <span>{renderMinutes()}{renderSeconds()}</span></p>
            </div> : null}
          </div>
          <button onClick={() => setShowNextWindow(!showNextWindow)} className='btn icon-close'></button>
        </div>
      );
    } else if (onlyText) return fullText ? 'Super Fast mode delivers in 60-90 mins' :
      'Get in 60-90 mins'
    return null;
  }

  if (asapReOpensAt) {
    return null
  }

  return (
    onlyText ?
      !asapEnabled ? isLoading ? "Checking..." : "Coming soon" :
        renderDeliveryTime()
      :
      <div>{renderDeliveryTime()}</div>

  )
}
